import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca`}</strong>{` -- initialize and manage a certificate authority`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca`}</strong>{` command group provides facilities to initialize a certificate
authority, retrieve the root of trust, sign and renew certificates, and create
and manage provisioners.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Create the configuration for a new certificate authority:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca init
`}</code></pre>
    <p>{`Configure the ca-url and root in the environment:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca bootstrap \\
  --ca-url https://ca.smallstep.com \\
  --fingerprint 0d7d3834cf187726cf331c40a31aa7ef6b29ba4df601416c9788f6ee01058cf3
$ cat $STEPPATH/config/defaults.json
{
  "ca-url": "https://ca.smallstep.com",
  "fingerprint": "0d7d3834cf187726cf331c40a31aa7ef6b29ba4df601416c9788f6ee01058cf3",
  "root": "/home/user/.step/certs/root_ca.crt"
}
`}</code></pre>
    <p>{`Download the root_ca.crt:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca root root_ca.crt \\
  --ca-url https://ca.smallstep.com \\
  --fingerprint 0d7d3834cf187726cf331c40a31aa7ef6b29ba4df601416c9788f6ee01058cf3
`}</code></pre>
    <p>{`Get the Health status of the CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca health --ca-url https://ca.smallstep.com --root /home/user/.step/certs/root_ca.crt
`}</code></pre>
    <p>{`Create a new certificate using a token:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ TOKEN=$(step ca token internal.example.com)
$ step ca certificate internal.example.com internal.crt internal.key \\
  --token $TOKEN --ca-url https://ca.smallstep.com --root root_ca.crt
`}</code></pre>
    <p>{`Renew a certificate (certificate must still be valid):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew internal.crt internal.key \\
  --ca-url https://ca.smallstep.com --root root_ca.crt
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "health/"
              }}>{`health`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`get the status of the CA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "init/"
              }}>{`init`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`initialize the CA PKI`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "bootstrap/"
              }}>{`bootstrap`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`initialize the environment to use the CA commands`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "token/"
              }}>{`token`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`generate an OTT granting access to the CA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "certificate/"
              }}>{`certificate`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`generate a new private key and certificate signed by the root certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "rekey/"
              }}>{`rekey`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`rekey a certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "renew/"
              }}>{`renew`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`renew a certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "revoke/"
              }}>{`revoke`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`revoke a certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "provisioner/"
              }}>{`provisioner`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`create and manage the certificate authority provisioners`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "sign/"
              }}>{`sign`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`generate a new certificate signing a certificate request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "root/"
              }}>{`root`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`download and validate the root certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "roots/"
              }}>{`roots`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`download all the root certificates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "federation/"
              }}>{`federation`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`download all the federated certificates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "acme/"
              }}>{`acme`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`manage ACME settings`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "policy/"
              }}>{`policy`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`manage certificate issuance policies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "admin/"
              }}>{`admin`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`create and manage the certificate authority admins`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      